// "use strict";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
// import { MathUtils } from 'three';
// import * as dat from "dat.gui";
import validate from "./modules/validate";
import sendForm from "./modules/send";
import slider from "./modules/slider";
import navDots from "./modules/navigation";
import linearAnimation from "./modules/linearAnimation";



window.addEventListener("load", () => {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);
  
  

  gsap.set('.contact, .promo, .vision, slider', {
    minHeight: window.innerHeight,
  })

  validate();
  sendForm();
  slider();
 



  gsap.to(window, { duration: 0.01, scrollTo: '.promo' });

  const renderer = new THREE.WebGLRenderer({ antialias: true });

  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.domElement.classList.add("themis");
  document.body.appendChild(renderer.domElement);

  const scene = new THREE.Scene();

  const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
  

  renderer.setClearColor(0x000000, 0);

  // const orbit = new OrbitControls(camera, renderer.domElement);
  // orbit.update();

  //   const axisHelper = new THREE.AxesHelper(10);
  //   scene.add(axisHelper);

  const grid = new THREE.GridHelper(30, 30);
  //   scene.add(grid);

  const ambientLight = new THREE.AmbientLight(0xffffff, 0);
    scene.add(ambientLight);

  const greenLight = new THREE.PointLight(0x0fff77, 1, 7.3, 0);
  scene.add(greenLight);
  greenLight.position.set(1.3, 4.4, 2.03);

  const greenLightHelper = new THREE.PointLightHelper(greenLight, 1);
  // scene.add(greenLightHelper);

  const purpleLight = new THREE.PointLight(0x4400ff, 12.12, 6, 0.9);
  scene.add(purpleLight);
  purpleLight.position.set(-2.78, 1.8, 0.8);

  const purpleLightHelper = new THREE.PointLightHelper(purpleLight, 1);
  // scene.add(purpleLightHelper);

  const directionalLightPurple = new THREE.DirectionalLight(0xC07EF7, 0);
    scene.add(directionalLightPurple);
  directionalLightPurple.position.set(-0.2, 8.84, -6.6);
  // scene.add( new THREE.DirectionalLightHelper(directionalLightPurple, 1))

  const directionalLightGreen = new THREE.DirectionalLight(0x0FFFAF, 0);
    scene.add(directionalLightGreen);
  directionalLightGreen.position.set(7.96, 4, 5.31);
  // scene.add( new THREE.DirectionalLightHelper(directionalLightGreen, 1))

const hemisphere = new THREE.HemisphereLight(0x00FF9D, 0xA600FF, 0)
scene.add(hemisphere);
hemisphere.position.set(4.43, 3, 3.54)
// scene.add(new THREE.HemisphereLightHelper(hemisphere, 1))



function createSprite(x,y,z,color, scale, opacity) {
  
  const spriteMaterial = new THREE.SpriteMaterial( 
    { 
      map: new THREE.TextureLoader().load( '../assets/3d/glow.png' ), 
      useScreenCoordinates: false, opacity: opacity,
      color: color, transparent: true, blending: THREE.AdditiveBlending
    });
    const sprite = new THREE.Sprite( spriteMaterial );
    sprite.scale.set(scale, scale, 1.0);
    scene.add(sprite); 
    sprite.position.set(x,y,z);
    return sprite
}

const spritePurple = createSprite(-5, 3, -15, 0x4400ff, 10, 0.4)
const spriteGreen = createSprite(1, 6, -5, 0x0fff77, 7, 0.1)



  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();

  let envMap2
  let envMap = new THREE.TextureLoader().load('../assets/3d/environment-map3.jpg', (texture) => {
    envMap2  = pmremGenerator.fromEquirectangular(texture).texture;
    envMap2.mapping = THREE.EquirectangularReflectionMapping;    
    // scene.environment = envMap;
    // texture.dispose();
    pmremGenerator.dispose();
})



  const assetLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  assetLoader.setDRACOLoader(dracoLoader);
  dracoLoader.setDecoderPath('assets/draco/')
  let model, modelObj;
  assetLoader.load(
    "../assets/3d/themis.glb",
    function (gltf) {
      model = gltf.scene;

      modelObj = model.getObjectByName("Object012")
      scene.add(model);
      
      modelObj.material.roughness = 0.28;
      modelObj.material.metalness = 1;
      modelObj.material.reflectivity = 1;
      modelObj.material.color.setHex(0xffffff);
      modelObj.material.transparent = true

      changeCameraFov();
      // modelObj.material.onBeforeCompile = (shader) => {

      //   shader.uniforms.uTime = { value: 0 };

      //   shader.fragmentShader = `
      //       uniform float uTime;
      //       mat4 rotationMatrix(vec3 axis, float angle) {
      //           axis = normalize(axis);
      //           float s = sin(angle);
      //           float c = cos(angle);
      //           float oc = 1.0 - c;
                
      //           return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
      //                       oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
      //                       oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
      //                       0.0,                                0.0,                                0.0,                                1.0);
      //       }
            
      //       vec3 rotate(vec3 v, vec3 axis, float angle) {
      //           mat4 m = rotationMatrix(axis, angle);
      //           return (m * vec4(v, 1.0)).xyz;
      //       }
      //   ` + shader.fragmentShader

      //   modelObj.material.userData.shader = shader
      // }
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;

      camera.position.set(4.21, 5.24, 1.44);
      camera.rotation.set(-2.63, 1.26, 2.65);

      camera.rotation.reorder("YXZ");

      const tl = gsap.timeline({
        defaults: {
          duration: 1,
          ease: "power1.out",
        },
        onComplete: () => {
          SmoothScroll({stepSize: 80})
          gsap.set('body, html', {touchAction: 'auto'})
        }
      })
      tl.to(".preloader", {
        autoAlpha: 0,
      })
      .from('.themis', {
        opacity: 0
      }, "<")
      .to(camera.position, {
        duration: 2,
        x: 0.59,
        y: 5.02,
        z: 5.31,
      }, "<")
      .to(camera.rotation, {
        duration: 2,
        x: -0.127,
        y: innerWidth > 576 ? 0.499 : 0.62,
        z: 0.0,
        onComplete: () => {
          gsapinit();
        },
      }, '<')
      .from('.promo__title', {
        y: 50,
        opacity: 0,
      }, "<")
      .from('.header, .dots', {
        opacity: 0,
        stagger: 0.5,
        pointerEvents: 'none'
      }, "<")
      .from('.promo__btn', {
        y: 50,
        opacity: 0,
      }, "<50%")
      .from('.promo__list', {
        y: 50,
        opacity: 0,
      }, "<50%")

    },
    undefined,
    function (error) {
      console.error(error);
    }
  );

  function gsapinit() {

    const promoTl = gsap.timeline({
      scrollTrigger: {
        trigger: 'body',
        start: 'top top',
        end: 'bottom bottom',
        scrub: true
      },
      defaults: {
        ease: "power1.inOut",
        // duration: 1
        // delay: 1,
        // repeatRefresh: true,
      },
    });


    promoTl
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".promo",
          start: "top top",
          end: "bottom top",
          // immediateRender: false,
          scrub: true,
        },
        x: 1.187,
        y: 6.093,
        z: 2.014,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".promo",
          start: "top top",
          end: "bottom top",
          scrub: true,
          // immediateRender: false,
          // preventOverlaps: true,
        },
        x: -0.469,
        y: 0.892,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "top top",
          end: "center center",
          scrub: true,
          // immediateRender: false,
          // preventOverlaps: true,
        },
        x: 1.722,
        y: 5.262,
        z: -1.390,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "top top",
          end: "center center",
          scrub: true,
          // immediateRender: false,
          // preventOverlaps: true,
        },
        x: -0.146,
        y: 2.725,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "center center",
          end: "80% top",
          scrub: true,
          // immediateRender: false,
          // preventOverlaps: true,
        },
        x: 2.41,
        y: 3.86,
        z: -1.54,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "center center",
          end: "80% top",
          scrub: true,
          // immediateRender: false,
          // preventOverlaps: true,
        },
        x: -0.02,
        y: 2.570,
        z: 0,
      })
      .fromTo(model.rotation, {
        y: 0,
      }, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "80% top",
          endTrigger: ".vision",
          end: "center center",
          scrub: true,
        },
        y: -4.14,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "80% top",
          endTrigger: ".vision",
          end: "center center",
          // immediateRender: false,
          scrub: true,
        },
        x: innerWidth > 576 ? 3.9 : 4.9,
        y: 4.9,
        z: -5.7,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".practice",
          start: "80% top",
          endTrigger: ".vision",
          end: "center center",
          // immediateRender: false,
          scrub: true,
        },
        x: -0.15,
        y: 2.92,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          end: "+=300",
          scrub: true,
          pin: window.innerWidth > 576 ? true : false,
          pinnedContainer: '.vision'
        },
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
          // immediateRender: false,
          pinnedContainer: '.vision'
        },
        x: 2.01,
        y: 3.68,
        z: 4.75,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
          // immediateRender: false,
          pinnedContainer: '.vision'
        },
        x: -0.001,
        y: 0.893,
        z: 0,
      })
      .to(model.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
        },
        y: 0,
      })
      .to(directionalLightPurple.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
        },
        x: -1.75,
        y: 6.19,
        z: 10,
      })
      .to(directionalLightGreen.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
        },
        x: 7.07,
        y: 4.65,
        z: 0.46,
      })
      .to(hemisphere.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
        },
        x: 2.44,
        y: 3.77,
        z: 0.02,
      })
      .to(renderer.domElement, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".vision",
          start: "center center",
          endTrigger: ".slider",
          end: "center center",
          scrub: true,
        },
       opacity: 0
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".slider",
          start: "center center",
          end: "+=300",
          scrub: true,
          pin: window.innerWidth > 576 ? true : false
        }
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".slider",
          start: "center center",
          endTrigger: ".values",
          end: "40% center",
          scrub: true,
        },
        x: 1.68,
        y: 4.18,
        z: 3.04,
      })
      .to(renderer.domElement, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".slider",
          start: "center center",
          endTrigger: ".values",
          end: "40% center",
          scrub: true,
        },
        opacity: 1
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".slider",
          start: "center center",
          endTrigger: ".values",
          end: "40% center",
          scrub: true,
        },
        x: -0.106,
        y: 1.021,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".values",
          start: "40% center",
          end: "bottom top",
          scrub: true,
        },
        x: 0.96,
        y: 2.51,
        z: 3.61,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".values",
          start: "40% center",
          end: "bottom top",
          scrub: true,
        },
        x: -0.027,
        y: 0.788,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".values",
          start: "bottom top",
          endTrigger: ".team",
          end: "bottom bottom",
          scrub: true,
        },
        x: -0.74,
        y: 2.65,
        z: 3.60,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".values",
          start: "bottom top",
          endTrigger: ".team",
          end: "bottom bottom",
          scrub: true,
        },
        x: -0.114,
        y: 1,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".contact",
          start: "top bottom",
          end: "bottom bottom",
          scrub: true,
        },
        x: 1.6,
        y: 4.8,
        z: 5.1,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".contact",
          start: "top bottom",
        //   endTrigger: ".team",
          end: "bottom bottom",
          scrub: true,
        },
        x: -0.26,
        y: 0.8,
        z: 0,
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".contact",
          start: "center center",
          end: "+=300",
          scrub: true,
          pin: window.innerWidth > 576 ? true : false,
        },
      })
      .to(camera.position, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".contact",
          start: "center center",
          end: "bottom top",
          scrub: true,
        },
        x: 2,
        y: 5.1,
        z: 5.5,
      })
      .to(camera.rotation, {
        scrollTrigger: {
          invalidateOnRefresh: true,
          trigger: ".contact",
          start: "center center",
          end: "bottom top",
          scrub: true,
        },
        x: -0.20,
        y: 0.768,
        z: 0,
      })

      // .to(camera.position, {
      //   scrollTrigger: {
      //     invalidateOnRefresh: true,
      //     trigger: ".contact",
      //     start: "bottom bottom",
      //     endTrigger: ".footer",
      //     end: "bottom bottom",
          // scrub: true,
      //   },
      //   x: 2.35,
      //   y: 3.64,
      //   z: 6.09,
      // })
      // .to(camera.rotation, {
      //   scrollTrigger: {
      //     invalidateOnRefresh: true,
      //     trigger: ".contact",
      //     start: "bottom bottom",
      //     endTrigger: ".footer",
      //     end: "bottom bottom",
      //     scrub: true,
      //   },
      //   x: -0.082,
      //   y: 0.748,
      //   z: 0,
      // });

      ScrollTrigger.create({
        trigger: '#vision',
        start:"top 70%",
        end:"bottom 10%", 
        invalidateOnRefresh: true,
        onEnter: () => {
          setColorWhite()
        },
        onLeaveBack: () => {
          setColorDark()
        },
    })
      ScrollTrigger.create({
        trigger: '#team',
        start:"top 70%",
        end:"bottom 10%", 
        invalidateOnRefresh: true,
        onEnter: () => {
          setColorDark()
        },
        onLeaveBack: () => {
          setColorWhite()
        },
    })
    ScrollTrigger.create({
      trigger: '#contact',
      start:"top 70%",
      end:"bottom 10%", 
      invalidateOnRefresh: true,
      onEnter: () => {
        setColorWhite()
      },
      onLeaveBack: () => {
        setColorDark()
      },
    })
    ScrollTrigger.create({
      trigger: '#footer',
      start:"top 70%",
      end:"bottom 10%", 
      invalidateOnRefresh: true,
      onEnter: () => {
        setColorDark()
      },
      onLeaveBack: () => {
        setColorWhite()
      },
    })

    linearAnimation();
    navDots();
  }

  const cursor = {
    x: 0,
    y: 0,
  }

  window.addEventListener('mousemove', (e) => {
    cursor.x = -(e.clientX / window.innerWidth - 0.5) / 10;
    cursor.y = (e.clientY / window.innerHeight - 0.5) / 10;
  })

  let posX = 0, posY = 0;


 
  const clock = new THREE.Clock();

  function animate() {

    if (model) {
      posX += (cursor.x - posX) / 20
      posY += (cursor.y - posY) / 20
      model.position.x = posX;
      model.position.y = posY;
    }
  
    const elepsedTime = clock.getElapsedTime();

    spritePurple.position.x = Math.cos(elepsedTime) - 5;
    spritePurple.position.z = Math.sin(elepsedTime) - 5;

    purpleLight.position.x = Math.cos(elepsedTime) - 2.78;
    purpleLight.position.y = Math.sin(elepsedTime) + 1.8;
    purpleLight.position.z = Math.sin(elepsedTime) + 0.8;

    greenLight.position.x = Math.cos(elepsedTime) + 1.3;
    greenLight.position.y = Math.sin(elepsedTime) + 4.4;
    

    // camera.updateMatrix();
    // camera.updateProjectionMatrix();
    // camera.updateMatrixWorld(true);
    // camera.updateWorldMatrix(true, true);
    renderer.render(scene, camera);
  }

  renderer.setAnimationLoop(animate);

  window.addEventListener("resize", function () {
    camera.aspect = window.innerWidth / window.innerHeight;

    changeCameraFov();
    camera.updateProjectionMatrix();

  });

  function changeCameraFov() {
    
    if (camera.aspect < 1) {
      camera.aspect = 1.3
      renderer.setSize( window.innerHeight * camera.aspect, window.innerHeight);
      renderer.domElement.style.right = 0;
      renderer.domElement.style.left = 'auto'
      scene.position.y = 1
      // scene.position.z = -2.5
      scene.scale.set(0.9,0.9,0.9)
    } else {
      renderer.setSize(window.innerWidth, window.innerHeight);
    }
    
    
    // change FOV
    // const planeAspectRatio = 16 / 9;

    // if (camera.aspect > planeAspectRatio) {
      // window too large
      // camera.fov = 45;
    // } else {
      // window too narrow
      // const cameraHeight = Math.tan(MathUtils.degToRad(45 / 2));
      // const ratio = camera.aspect / planeAspectRatio;
      // const newCameraHeight = cameraHeight / ratio;
      // camera.fov = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 1.7;
      // if (window.innerWidth < 576) {
      //   scene.scale.set(1.1,1.1,1.1)
      // } else {
      //   scene.scale.set(1,1,1)
      // }
    // }



    camera.updateProjectionMatrix();
  }


  function setColorWhite() {
    modelObj.material.envMap = envMap2;
    greenLight.intensity = 1.25;
    purpleLight.intensity = 0
    spriteGreen.material.opacity = 0;
    spritePurple.material.opacity = 0;
    renderer.toneMappingExposure = 0.51
    gsap.to(modelObj.material, {
      duration: 0.5,
      roughness: 0.15,
      metalness:  0.21,
    })
    gsap.to(hemisphere, {
      duration: 0.5,
      intensity: 2.22
    })
    gsap.to(directionalLightPurple, {
      duration: 0.5,
      intensity: 6.19
    })
    gsap.to(directionalLightGreen, {
      duration: 0.5,
      intensity: 3.38
    })
    gsap.to(ambientLight, {
      duration: 0.5,
      intensity: 0
    })
    gsap.to(spriteGreen.material, {
      duration: 0.5,
      opacity: 0
    })
    gsap.to(spritePurple.material, {
      duration: 0.3,
      opacity: 0
    })
  }

  function setColorDark() {
    modelObj.material.envMap = null;
    gsap.to(modelObj.material, {
      duration: 0.5,
      roughness: 0.28,
      metalness:  1
    })
    gsap.to(greenLight, {
      duration: 0.5,
      intensity: 2.78,
    })
    gsap.to(purpleLight, {
      duration: 0.5,
      intensity: 12.12,
    })
    gsap.to(hemisphere, {
      duration: 0.5,
      intensity: 0
    })
    gsap.to(directionalLightPurple, {
      duration: 0.5,
      intensity: 0
    })
    gsap.to(directionalLightGreen, {
      duration: 0.5,
      intensity: 0
    })
    gsap.to(ambientLight, {
      duration: 0.5,
      intensity: 0
    })
    gsap.to(spriteGreen.material, {
      duration: 0.5,
      opacity: 0.1,
    })
    gsap.to(spritePurple.material, {
      duration: 0.3,
      ease: "power4.in",
      opacity: 0.4
    })
  }
  // function gui() {
  //   const gui = new dat.GUI();
  //   const options = {
  //     camPosX: camera.position.x,
  //     camPosY: camera.position.y,
  //     camPosZ: camera.position.z,
  //     camRotateX: 0,
  //     camRotateY: 0,
  //     camRotateZ: 0,
  //     fov: 0,
  //     exposition: 0,
  //     metal: 0,
  //     rough: 0,
  //   };
  //   let cameraFolder = gui.addFolder("Camera");
  //   cameraFolder.add(options, "camPosX", -10, 10, 0.1).onChange((e) => {
  //     camera.position.x = e;
  //   });
  //   cameraFolder.add(options, "camPosY", -10, 10, 0.1).onChange((e) => {
  //     camera.position.y = e;
  //   });
  //   cameraFolder.add(options, "camPosZ", -10, 10, 0.1).onChange((e) => {
  //     camera.position.z = e;
  //   });
  //   cameraFolder.add(options, "camRotateX", -6, 6, 0.01).onChange((e) => {
  //     camera.rotation.x = e;
  //   });
  //   cameraFolder.add(options, "camRotateY", -6, 6, 0.01).onChange((e) => {
  //     camera.rotation.y = e;
  //   });
  //   cameraFolder.add(options, "camRotateZ", -6, 6, 0.01).onChange((e) => {
  //     camera.rotation.z = e;
  //   });
  //   cameraFolder.add(options, "fov", 0, 100, 1).onChange((e) => {
  //     camera.fov = e;
  //   });
  //   cameraFolder.add(options, "exposition", 0, 3, 0.01).onChange((e) => {
  //     renderer.toneMappingExposure = e
  //   });
  //   cameraFolder.add(options, "rough", 0, 3, 0.01).onChange((e) => {
  //     modelObj.material.roughness = e;
  //   });
  //   cameraFolder.add(options, "metal", 0, 3, 0.01).onChange((e) => {
  //     modelObj.material.metalness = e
  //   });
  //   const greenLightOpt = {
  //     x: greenLight.position.x,
  //     y: greenLight.position.y,
  //     z: greenLight.position.z,
  //     intensity: greenLight.intensity,
  //     distance: greenLight.distance,
  //     decay: greenLight.decay,
  //   };
  //   let greenLightGui = gui.addFolder("Green Light");
  //   greenLightGui.add(greenLightOpt, "x", -10, 10, 0.01).onChange((e) => {
  //     greenLight.position.x = e;
  //   });
  //   greenLightGui.add(greenLightOpt, "y", -10, 10, 0.01).onChange((e) => {
  //     greenLight.position.y = e;
  //   });
  //   greenLightGui.add(greenLightOpt, "z", -10, 10, 0.01).onChange((e) => {
  //     greenLight.position.z = e;
  //   });
  //   greenLightGui.add(greenLightOpt, "intensity", 0, 15, 0.01).onChange((e) => {
  //     greenLight.intensity = e;
  //   });
  //   greenLightGui.add(greenLightOpt, "distance", 0, 10, 0.01).onChange((e) => {
  //     greenLight.distance = e;
  //   });
  //   greenLightGui.add(greenLightOpt, "decay", 0, 10, 0.01).onChange((e) => {
  //     greenLight.decay = e;
  //   });
  //   const purpleLightOpt = {
  //     x: purpleLight.position.x,
  //     y: purpleLight.position.y,
  //     z: purpleLight.position.z,
  //     intensity: purpleLight.intensity,
  //     distance: purpleLight.distance,
  //     decay: purpleLight.decay,
  //   };
  //   let purpleLightGui = gui.addFolder("purple Light");
  //   purpleLightGui.add(purpleLightOpt, "x", -10, 10, 0.01).onChange((e) => {
  //     purpleLight.position.x = e;
  //   });
  //   purpleLightGui.add(purpleLightOpt, "y", -10, 10, 0.01).onChange((e) => {
  //     purpleLight.position.y = e;
  //   });
  //   purpleLightGui.add(purpleLightOpt, "z", -10, 10, 0.01).onChange((e) => {
  //     purpleLight.position.z = e;
  //   });
  //   purpleLightGui.add(purpleLightOpt, "intensity", 0, 15, 0.01).onChange((e) => {
  //     purpleLight.intensity = e;
  //   });
  //   purpleLightGui.add(purpleLightOpt, "distance", 0, 10, 0.01).onChange((e) => {
  //     purpleLight.distance = e;
  //   });
  //   purpleLightGui.add(purpleLightOpt, "decay", 0, 10, 0.01).onChange((e) => {
  //     purpleLight.decay = e;
  //   });

  //   const directPurplOpt = {
  //     x: directionalLightPurple.position.x,
  //     y: directionalLightPurple.position.y,
  //     z: directionalLightPurple.position.z,
  //     intensity: directionalLightPurple.intensity,
  //     color: '#ffea00'
  //   };
  //   let directionalLightPurpleGui = gui.addFolder("directional purple");
  //   directionalLightPurpleGui.add(directPurplOpt, "x", -10, 10, 0.01).onChange((e) => {
  //     directionalLightPurple.position.x = e;
  //   });
  //   directionalLightPurpleGui.add(directPurplOpt, "y", -10, 10, 0.01).onChange((e) => {
  //     directionalLightPurple.position.y = e;
  //   });
  //   directionalLightPurpleGui.add(directPurplOpt, "z", -10, 10, 0.01).onChange((e) => {
  //     directionalLightPurple.position.z = e;
  //   });
  //   directionalLightPurpleGui.add(directPurplOpt, "intensity", 0, 15, 0.01).onChange((e) => {
  //     directionalLightPurple.intensity = e;
  //   });
  //   directionalLightPurpleGui.addColor(directPurplOpt, "color").onChange((e) => {
  //     directionalLightPurple.color.setHex('0x'+e.slice(-6))
  //   });
  //   const directGreenOpt = {
  //     x: directionalLightPurple.position.x,
  //     y: directionalLightPurple.position.y,
  //     z: directionalLightPurple.position.z,
  //     intensity: directionalLightPurple.intensity,
  //     color: '#ffea00',
  //   };
  //   let directionalLightGreenGui = gui.addFolder("directional green");
  //   directionalLightGreenGui.add(directGreenOpt, "x", -10, 10, 0.01).onChange((e) => {
  //     directionalLightGreen.position.x = e;
  //   });
  //   directionalLightGreenGui.add(directGreenOpt, "y", -10, 10, 0.01).onChange((e) => {
  //     directionalLightGreen.position.y = e;
  //   });
  //   directionalLightGreenGui.add(directGreenOpt, "z", -10, 10, 0.01).onChange((e) => {
  //     directionalLightGreen.position.z = e;
  //   });
  //   directionalLightGreenGui.add(directGreenOpt, "intensity", 0, 15, 0.01).onChange((e) => {
  //     directionalLightGreen.intensity = e;
  //   });
  //   directionalLightGreenGui.addColor(directGreenOpt, "color").onChange((e) => {
  //     directionalLightGreen.color.set(e)
  //   });

  //   const hemisphereOpt = {
  //     x: hemisphere.position.x,
  //     y: hemisphere.position.y,
  //     z: hemisphere.position.z,
  //     intensity: hemisphere.intensity,
  //     color1: '#FF0000',
  //     color2: '#FF0000',
  //   };
  //   let hemisphereGui = gui.addFolder("hemisphere");
  //   hemisphereGui.add(hemisphereOpt, "x", -10, 10, 0.01).onChange((e) => {
  //     hemisphere.position.x = e;
  //   });
  //   hemisphereGui.add(hemisphereOpt, "y", -10, 10, 0.01).onChange((e) => {
  //     hemisphere.position.y = e;
  //   });
  //   hemisphereGui.add(hemisphereOpt, "z", -10, 10, 0.01).onChange((e) => {
  //     hemisphere.position.z = e;
  //   });
  //   hemisphereGui.add(hemisphereOpt, "intensity", 0, 15, 0.01).onChange((e) => {
  //     hemisphere.intensity = e;
  //   });
  //   hemisphereGui.addColor(hemisphereOpt, "color1").onChange((e) => {
  //     hemisphere.groundColor.setHex('0x'+e.slice(-6))
  //   });
  //   hemisphereGui.addColor(hemisphereOpt, "color2").onChange((e) => {
  //     hemisphere.color.setHex('0x'+e.slice(-6))
  //   });
  // }

  // gui();
  
});

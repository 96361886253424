function linearAnimation() {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);

  let mm = gsap.matchMedia();
  mm.add(
    {
      isMobile: "(max-width: 576px)",
      isDesktop: "(min-width: 577px)",
    },
    (context) => {
      let { isMobile, isDesktop } = context.conditions;


      gsap.to(".promo__title", {
        scrollTrigger: {
          trigger: ".promo__title",
          start: "top 5%",
          end: "bottom 1%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.to(".promo__btn", {
        scrollTrigger: {
          trigger: ".promo__btn",
          start: isDesktop ? "top 8%" : "top 5%",
          end: "bottom 1%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.to(".promo__list", {
        scrollTrigger: {
          trigger: ".promo__list",
          start: isDesktop ? "top 8%" : "top 5%",
          end: "bottom 1%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      const titles = gsap.utils.toArray(".title:not(li)");

      titles.forEach((title) => {
        let pinContainer = null;
        if (title.classList.contains('vision__title')) {
            pinContainer = '.vision'
        }
        if (title.classList.contains('slider__title')) {
            pinContainer = '.slider'
        }
        if (title.classList.contains('contact__title')) {
            pinContainer = '.contact'
        }
        gsap.to(title,{
            scrollTrigger: {
              trigger: title,
              start: isDesktop ? "top 2%" : "top 10%",
              end: "bottom 1%",
              scrub: true,
              pinnedContainer: pinContainer
            },
            ease: "power1.out",
            opacity: 0, y: isDesktop ? -50 : -30
          });

        gsap.from(title, {
          scrollTrigger: {
            trigger: title,
            start: "top 80%",
            end: "top 55%",
            scrub: true,
          },
          ease: "power1.out",
          opacity: 0,
          y: isDesktop ? 50 : 30,
        });
        
      });


      const areas = gsap.utils.toArray(".columns__item");

      areas.forEach((item) => {
        gsap.to(item, {
            scrollTrigger: {
              trigger: item,
              start: isDesktop ? "top 15%" : "top: 10%",
              end: "bottom 1%",
              scrub: true,
            },
            ease: "power1.out",
            opacity: 0,
            y: isDesktop ? -50 : -30
          });

        gsap.from(item, {
          scrollTrigger: {
            trigger: item,
            start: "top 90%",
            end: "top 65%",
            scrub: true,
          },
          ease: "power1.out",
          opacity: 0,
          y: isDesktop ? 50 : 30
        });
     
      });

      gsap.to(".vision__welcome", {
        scrollTrigger: {
          trigger: ".vision__welcome",
          start: isDesktop ? "top 5%" : "top 4%",
          end: "bottom 1%",
          scrub: true,
          pinnedContainer: '.vision'
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });
      
      gsap.from(".vision__welcome", {
        scrollTrigger: {
          trigger: ".vision__welcome",
          start: "top 80%",
          end: "top 55%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? 50 : 30,
      });



      const visionText = gsap.utils.toArray(".vision__text p");

      visionText.forEach((text) => {

        gsap.to(text, {
            scrollTrigger: {
              trigger: text,
              start: "top 10%",
              end: "bottom top",
              scrub: true,
              // invalidateOnRefresh: true,
              pinnedContainer: '.vision'
            },
            ease: "power1.out",
            opacity: 0,
            y: isDesktop ? -50 : -30,
          });


        gsap.from(text, {
          scrollTrigger: {
            trigger: text,
            start: "top 90%",
            end: "top 85%",
            scrub: true,
            // invalidateOnRefresh: true,
          },
          ease: "power1.out",
          opacity: 0,
          y: isDesktop ? 50 : 30,
        });

      });

      gsap.from(".vision__founder", {
        scrollTrigger: {
          trigger: ".vision__founder",
          start: "top 90%",
          end: "top 85%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? 50 : 30,
      });

      gsap.to(".vision__founder", {
        scrollTrigger: {
          trigger: ".vision__founder",
          start: "top 10%",
          end: "bottom top",
          scrub: true,
          invalidateOnRefresh: true,
          pinnedContainer: '.vision',
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.from(".slider__swiper ", {
        scrollTrigger: {
          trigger: ".slider__swiper ",
          start: "top 90%",
          end: "top  85%",
          scrub: true,
          pinnedContainer: '.slider'
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? 50 : 30,
      });

      gsap.to(".slider__swiper ", {
        scrollTrigger: {
          trigger: ".slider__swiper ",
          start: isDesktop ? "top 15%" : "top 10%",
          end: "bottom 1%",
          scrub: true,
          invalidateOnRefresh: true,
          pinnedContainer: '.slider'
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.from(".columns__descr", {
        scrollTrigger: {
          trigger: ".columns__descr",
          start: "top 80%",
          end: "top  55%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? 50 : 30,
      });

      gsap.to(".columns__descr", {
        scrollTrigger: {
          trigger: ".columns__descr",
          start: isDesktop ? "top 5%" : "top: 2%",
          end: "bottom 1%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.to(".contact__item", {
        scrollTrigger: {
          trigger: ".contact__wrapper",
          start: isDesktop ? "top 20%" : "top top",
          end: "bottom top",
          scrub: true,
          pinnedContainer: '.contact'
        },
        stagger: 0.2,
        ease: "power1.inOut",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.to(".contact__btn", {
        scrollTrigger: {
          trigger: ".contact__btn",
          start: isDesktop ? "top 20%" : "top: 15%",
          end: "top top",
          scrub: true,
          pinnedContainer: '.contact'
        },
        stagger: 0.2,
        ease: "power1.inOut",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.to(".contact__label", {
        scrollTrigger: {
          trigger: ".contact__label",
          start: isDesktop ? "top 20%" : "top: 15%",
          end: "top top",
          scrub: true,
          pinnedContainer: '.contact'
        },
        stagger: 0.2,
        ease: "power1.inOut",
        opacity: 0,
        y: isDesktop ? -50 : -30,
      });

      gsap.from(".footer__copy", {
        scrollTrigger: {
          trigger: ".footer__copy",
          start: "top bottom",
          end: "bottom 98%",
          scrub: true,
        },
        ease: "power1.out",
        opacity: 0.5,
        y: isDesktop ? 50 : 30,
      });
    }
  );
}

module.exports = linearAnimation;

function validate () {
    const sendBtn = document.querySelector('#send');
    const inputName = document.querySelector('input[name="name"]');
    const inputEmail = document.querySelector('input[name="email"]');
    const inputTextarea = document.querySelector('textarea[name="text"]');
    const inputNews = document.querySelector('input[name="newslatter"]');
    const inputPolicy = document.querySelector('input[name="policy"]');
    const labelNews = document.querySelector('.contact__label:first-child');
    const labelPolicy = document.querySelector('.contact__label:last-child');
    const NAME_REGEXP = /[a-zA-Zа-яА-я]/g;
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    const TEXAREA_REGEXP = /./g;
    function isInputNameValid(value) {
        return (NAME_REGEXP.test(value) && value.length > 2);
    }
    function isInputEmailValid(value) {
        return (EMAIL_REGEXP.test(value) && value.length > 8);
    }
    function isTxtareaValid(value) {
        return (TEXAREA_REGEXP.test(value));
    }

    function isInputPolicyValid() {
        return inputPolicy.checked;
    }
  
    // function inputChecked(nameOfInput, inputLabel) {
    //     nameOfInput.addEventListener('click', () => {
    //         if (nameOfInput.checked) {
    //             inputLabel.classList.add('contact__label--checked')
    //         } else {
    //             inputLabel.classList.remove('contact__label--checked')
    //        }
    //     })
    // }

    // inputChecked(inputNews, labelNews);
    // inputChecked(inputPolicy, labelPolicy);
    
    function validateInputs(nameOfInput, validTest) {
        function disableBtn() {
            if(isInputEmailValid(inputEmail.value) && isInputNameValid(inputName.value) && isInputPolicyValid()) {
                sendBtn.removeAttribute('disabled');
                sendBtn.classList.remove('contact__btn--disable');
            } else {
                sendBtn.setAttribute('disabled', '');
                sendBtn.classList.add('contact__btn--disable');
            }
        }
        function onInput() {
            if(validTest(nameOfInput.value)) {
                nameOfInput.classList.remove('contact__input--error');
                nameOfInput.classList.add('contact__input--check');
            } else {
                nameOfInput.classList.remove('contact__input--check');
                nameOfInput.classList.add('contact__input--error')
            }
        }
        nameOfInput.addEventListener('change', onInput);
        nameOfInput.addEventListener('change', disableBtn);
    }
    
    validateInputs(inputName, isInputNameValid);
    validateInputs(inputEmail, isInputEmailValid);
    validateInputs(inputTextarea, isTxtareaValid);
    validateInputs(inputPolicy, isInputPolicyValid);
}

module.exports = validate;
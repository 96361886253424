function navDots () {
    // gsap.registerPlugin(ScrollToPlugin);
    // gsap.registerPlugin(ScrollTrigger);

    const burgerBtn = document.querySelector('.header__burger');
    const menu = document.querySelector('.dots')

    burgerBtn.addEventListener('click', () => {
        if (burgerBtn.classList.contains('open')) {
            burgerBtn.classList.remove('open')
            menu.classList.remove('open')
        } else {
            burgerBtn.classList.add('open')
            menu.classList.add('open')
        }
    })

    const navItems = document.querySelectorAll('.dots__item');
    let sections = gsap.utils.toArray('.section');

    function remooveActiveClass () {
        navItems.forEach(item => {
            item.classList.remove('active');
        })
    }
    navItems.forEach((item, i) => {

        item.addEventListener('click', (e) => {
            e.preventDefault();
            if (burgerBtn.classList.contains('open')) {
                burgerBtn.classList.remove('open')
                menu.classList.remove('open')
            }
            gsap.to(window, { duration: 2, ease: "power2.out", scrollTo: sections[i]});
        })
        
    });

   

    function toggleColor (trigger, toColor, fromColor, toBorderOpacity, fromBorderOpacity) {
        ScrollTrigger.create({
            trigger: trigger,
            start:"top 70%",
            end:"bottom 10%", 
            invalidateOnRefresh: true,
            // toggleClass: {targets: "body", className: bodyClass},
            onEnter: () => {
              gsap.to('.color', { duration: .5, backgroundColor: toColor})
              gsap.to('.dots__item', { duration: .5, color: fromColor})
              gsap.to('body', { duration: .1, color: fromColor})
            //   gsap.to('.borders', { duration: .1, opacity: toBorderOpacity})
              
            },
            onLeaveBack: () => {
              gsap.to('.color', { duration: .5, backgroundColor: fromColor})
              gsap.to('.dots__item', { duration: .5, color: toColor})
              gsap.to('body', { duration: .1, color: toColor})
            //   gsap.to('.borders', { duration: .1, opacity: fromBorderOpacity})
            },
        })
        
    }
    toggleColor('#vision', '#ffffff', '#010101', 0.1, 0.2);
    toggleColor('#team', '#010101', '#ffffff', 0.2, 0.1);
    toggleColor('#contact', '#ffffff', '#010101',  0.1, 0.2);
    toggleColor('#footer', '#010101', '#ffffff', 0.2, 0.1);

    
 
    sections.forEach((item, i) => {
        gsap.to(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 70%",
                end: "bottom 70%",
                // toggleActions: "play none restart reverse",
                toggleActions: "play none none reverse",

                // invalidateOnRefresh: true,
                onToggle: (self) => {
                   if ( self.isActive) {
                        remooveActiveClass();
                        navItems[i].classList.add('active')
                   }
                    
                }
            },
           
        })
    });
    const logo = document.querySelector('.header__logo');
    logo.addEventListener('click', () => {
        gsap.to(window, { duration: 2, ease: "power2.out", scrollTo: '.promo' });
    })

    const contactBtn = document.querySelectorAll('.promo__btn, .to-contact');

    contactBtn.forEach(btn => {
        btn.addEventListener('click', () => {
            gsap.to(window, { duration: 2, ease: "power2.out", scrollTo: '.contact' });
        })

    })
}

module.exports = navDots;
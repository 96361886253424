function sendForm () {
    let form = document.querySelector("#form");
    const serverResponse = document.querySelector('.contact__response');
    const sendAgain = document.querySelector('.contact__btn--again');
    const send = document.querySelector('#send');
    const textInputs = document.querySelectorAll('.contact__input');
    const checkboxes = document.querySelectorAll('.contact__label');
    form.addEventListener("submit", async function (event) {
        event.preventDefault();
        let response = await fetch("/mailer/mail.php", {
            method: "POST",
            body: new FormData(form),
        });

        if (response.ok) {
            serverResponse.classList.add('contact__response--visible');
            form.classList.add('contact__form--sended')
        } else {
            console.log('error')
            // serverResponse.classList.add('contact__response--visible');
        }
        form.reset();
        
        
  });
  sendAgain.addEventListener("click", () => {
    serverResponse.classList.remove("contact__response--visible");
    send.setAttribute("disabled", '');
    send.classList.add('contact__btn--disable');
    textInputs.forEach(input => {
        input.classList.remove('contact__input--check')
    })
    form.classList.remove('contact__form--sended')
});
}

module.exports = sendForm;